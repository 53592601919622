import {
    Flex,
    Text,
    Image,
    SimpleGrid,
    Box,
    Link,
    Center,
    Button,
} from "@chakra-ui/react";

import "./Landing.css";

import Hanwha from "../assets/hanwha.png";
import Ringle from "../assets/ringle.png";
import Background1 from "../assets/background1.jpeg";
// import Background2 from "../assets/background2.jpeg";
// import Background3 from "../assets/background3.jpeg";
// import Background4 from "../assets/background4.jpeg";

import { isMobile } from "react-device-detect";

import { useEffect } from "react";

function Landing({ setLanding }) {
    useEffect(() => {
        setLanding(true);
    });

    return (
        <>
            {isMobile ? (
                <Flex
                    justify="center"
                    align="center"
                    width="100%"
                    flexDirection="column"
                >
                    <Flex
                        className="overlay"
                        justify="center"
                        height="50vh"
                        backgroundImage={Background1}
                    >
                        <Text
                            fontSize="2xl"
                            textShadow="0 3px #000000"
                            color="white"
                            position="absolute"
                            top="25vh"
                        >
                            Welcome to Harvard College KISA
                        </Text>
                    </Flex>
                    <Flex
                        justify="center"
                        align="center"
                        flexDirection="column"
                        width="80vw"
                        paddingTop="50px"
                        paddingBottom="50px"
                    >
                        {/* <Text fontSize="25px">Application Open!</Text>
                        <Link
                            href="https://docs.google.com/forms/d/e/1FAIpQLSfm3iJuBzPUN33qZjb9qbuH-0sWUHG_SUgY5gN5TUVLIv05cA/viewform?usp=sf_link"
                            isExternal
                            style={{ textDecoration: "none" }}
                        >
                            <Button colorScheme="red">JOIN NOW</Button>
                        </Link>
                        <br /> */}

                        <Text fontSize="18px" color="Black" textAlign="left">
                            The Harvard College Korean International Students
                            Association (HCKISA) is a student-run organization
                            that brings together undergraduates who identify as
                            Korean internationals. Our purpose is to provide a
                            sense of home and community for our membership who,
                            for the most part, have traveled halfway across the
                            world for college. We host events such as movie
                            screenings, study breaks, outings, recruiting
                            events, and mixers with other Korean associations
                            around the Boston area to support the academic,
                            social, and professional development of our
                            community members.
                        </Text>
                        <br />
                        <br />
                        <Text fontSize="18px" color="Black" textAlign="justify">
                            저희 HCKISA는 하버드 대학교의 학생 운영 한인회
                            입니다. 저희는 대학을 위해 지구 반대편까지 여행한
                            우리 회원들에게 고향과 공동체 의식을 제공하는 것을
                            목적으로 하고 있습니다. 저희는 영화 상영, 스터디
                            브레이크, 리크루팅 이벤트, 다른 한인회와의 교류 행사
                            등의 다양한 이벤트를 개최하여 저희 구성원들의
                            학문적, 사회적, 전문적인 발전을 지원합니다.
                        </Text>
                    </Flex>

                    <Text fontSize="3xl" fontFamily="Ubuntu" color="black">
                        Our Partners
                    </Text>
                    <Box paddingTop="10px" width="40%">
                        <SimpleGrid minChildWidth="100px" spacing="40px">
                            <Center>
                                <Image src={Hanwha} width="150px" />
                            </Center>
                            <Center>
                                <Image src={Ringle} width="120px" />
                            </Center>
                        </SimpleGrid>
                    </Box>
                </Flex>
            ) : (
                <>
                    {
                        // web view
                    }
                    <Flex
                        justify="center"
                        align="center"
                        width="100%"
                        flexDirection="column"
                    >
                        <Flex
                            className="overlay"
                            justify="center"
                            backgroundImage={Background1}
                        >
                            <Text
                                fontSize="50px"
                                textShadow="0 3px #000000"
                                color="white"
                                position="absolute"
                                top="35vh"
                            >
                                Welcome to Harvard College KISA
                            </Text>
                        </Flex>

                        <Flex
                            justify="center"
                            align="center"
                            flexDirection="column"
                            width="60vw"
                            paddingTop="70px"
                            paddingBottom="70px"
                        >
                            {/* <Text fontSize="30px">Application Open!</Text>
                            <br />
                            <Link
                                href="https://docs.google.com/forms/d/e/1FAIpQLSfm3iJuBzPUN33qZjb9qbuH-0sWUHG_SUgY5gN5TUVLIv05cA/viewform?usp=sf_link"
                                isExternal
                                style={{ textDecoration: "none" }}
                            >
                                <Button colorScheme="red">JOIN NOW</Button>
                            </Link>
                            <br /> */}
                            <br />
                            <Text
                                fontSize="18px"
                                color="Black"
                                textAlign="justify"
                            >
                                The Harvard College Korean International
                                Students Association (HCKISA) is a student-run
                                organization that brings together undergraduates
                                who identify as Korean internationals. Our
                                purpose is to provide a sense of home and
                                community for our membership who, for the most
                                part, have traveled halfway across the world for
                                college. We host events such as movie
                                screenings, study breaks, outings, recruiting
                                events, and mixers with other Korean
                                associations around the Boston area to support
                                the academic, social, and professional
                                development of our community members.
                            </Text>
                            <br />
                            <br />
                            <Text
                                fontSize="18px"
                                color="Black"
                                textAlign="justify"
                            >
                                저희 HCKISA는 하버드 대학교의 학생 운영 한인회
                                입니다. 저희는 대학을 위해 지구 반대편까지
                                여행한 우리 회원들에게 고향과 공동체 의식을
                                제공하는 것을 목적으로 하고 있습니다. 저희는
                                영화 상영, 스터디 브레이크, 리크루팅 이벤트,
                                다른 한인회와의 교류 행사 등의 다양한 이벤트를
                                개최하여 저희 구성원들의 학문적, 사회적,
                                전문적인 발전을 지원합니다.
                            </Text>
                        </Flex>
                        <Text fontSize="40px" fontFamily="Ubuntu" color="black">
                            Our Partners
                        </Text>

                        <Box paddingTop="20px" width="35%">
                            <SimpleGrid minChildWidth="110px" spacing="40px">
                                <Center>
                                    <Image src={Hanwha} width="150px" />
                                </Center>
                                <Center>
                                    <Image src={Ringle} width="120px" />
                                </Center>
                            </SimpleGrid>
                        </Box>
                    </Flex>
                </>
            )}
        </>
    );
}

export default Landing;
